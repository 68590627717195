
import Kirbylink from './kirbylink.vue' 
export default {
  name: "bethechange",
  props: {
    content: Object,
  },
  components: {
    Kirbylink
  },
    mounted(){ 
    },
    methods:{
       
    }
};
